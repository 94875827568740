<template>
	<CRow>
		<CCol sm="12">
			<TopHeadline :headerTitle="$t('pages.account.changePassword')" :backTitle="$t('common.back')" :onGoBack="onBackPage">
				<template v-slot:headerRight>
					<CButton type="button" name="confirm-changePassword" color="primary" @click="onSaveChangePassword" class="btn btn-success">
						{{ $t('pages.account.savePassword') }}
					</CButton>					
				</template>
			</TopHeadline>

			<Accordion :headerTitle="$t('pages.account.changePassword')">
				<template v-slot:body>
					<CRow>
						<CCol sm="12">
							<CForm>
								<CInput type="password" :placeholder="$t('pages.account.enterpasswordold')" v-model="oldPassword"
										required horizontal autocomplete="off">
									<template #label>
										<div class="col-sm-3 col-form-label">
											{{ $t('pages.account.passwordold') }}
											<small class="requiredInput">(*)</small>
										</div>
									</template>
								</CInput>

								<CInput type="password" :placeholder="$t('pages.account.enterpasswordNew')"
										:is-valid="validatorPassword" required v-model="newPassword" horizontal autocomplete="off">
									<template #invalid-feedback>
										<div class="invalid-feedback">
											{{ $t('pages.account.passwordNew') }}, {{ $t('pages.account.limitPasswordLength') }}
										</div>
									</template>
									<template #label>
										<div class="col-sm-3 col-form-label">
											{{ $t('pages.account.passwordNew') }}
											<small class="requiredInput">(*)</small>
										</div>
									</template>
								</CInput>

								<CInput type="password" :placeholder="$t('pages.account.enterRetypePassword')"
										:is-valid="validatorPassword" v-model="retypeNewPassword" required horizontal autocomplete="off">
									<template #invalid-feedback>
										<div class="invalid-feedback">
											{{ $t('pages.account.enterRetypePassword') }}, {{ $t('pages.account.limitPasswordLength') }}
										</div>
									</template>
									<template #label>
										<div class="col-sm-3 col-form-label">
											{{ $t('pages.account.passwordConfirmation') }}
											<small class="requiredInput">(*)</small>
										</div>
									</template>
								</CInput>
							</CForm>
						</CCol>
					</CRow>
				</template>
			</Accordion>

			<Confirmation ref="confirmation"></Confirmation>
		</CCol>
	</CRow>
</template>

<script>

    import i18n from '@/plugins/i18n'
	import { mapGetters, mapActions } from 'vuex'
    import Confirmation from '@/components/Confirmation.vue'
    import Accordion from '@/components/Accordion.vue'    
    import TopHeadline from '@/components/TopHeadline.vue'

	export default {
        name: 'ChangePassword',
		data() {
			return {
				horizontal: { label:'col-3', input:'col-9' },					
				oldPassword: '',
                newPassword: '',
                retypeNewPassword: '',
			}
		},
        components: {
            Confirmation,
            Accordion,
            TopHeadline
        },
        computed: {           
			...mapGetters('auth', ['user'])
        },  
		methods: {
            ...mapActions('profile', ['changePassword']),

			validatorPassword(val) {
				return val ? val.length > 5 : false
			},
            onBackPage() {
                this.$router.push('/users/profile');
                //this.$router.go(-1);
            },
            async onSaveChangePassword() {

				if (this.oldPassword === '') {
                    this.$store.dispatch('alert/error', i18n.t('pages.account.enterpasswordold'));
                    return;
                }                

                if (this.newPassword === '') {
                    this.$store.dispatch('alert/error', i18n.t('pages.account.enterpasswordNew'));
                    return;
                }
                else if (!this.validatorPassword(this.newPassword)) {
                    this.$store.dispatch('alert/error', i18n.t('pages.account.limitPasswordLength'));
                    return;
				}
                
                if (this.newPassword != this.retypeNewPassword)
				{															
                    this.$store.dispatch('alert/error', i18n.t('pages.account.paswordNotMatch'));
					return;
				}			
                
                var retVal = await this.changePassword({ userId: this.$store.state.auth.user.sub, oldPassword: this.oldPassword, newPassword: this.newPassword, retypePassword: this.retypeNewPassword });
				if (retVal == true) {
					this.oldPassword = '';
                    this.onBackPage();
                }
			}
		},		
	}
</script>

